import React from 'react';
import ReactTypingEffect from 'react-typing-effect';

const NotYet = () => (
  <ReactTypingEffect
    className="message"
    text={[
      "Intelligence is not artificial.",

      "We are humans...",
      "We are building something amazing for you.",
      "We are working hard to bring you the best experience.",

      "Keep an eye on this space for updates.", "" +
      "Your wait will be worth it!",

      "Cheers,",
      "The Linka Cloud Team",
    ]}
    speed={100}
    eraseDelay={3000}
    eraseSpeed={10}
    typingDelay={1000}
  />
)

function App() {
  return (
    <NotYet />
  );
}

export default App;
